import React from "react"
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      "האם יש לכם רעיון מבריק לאפליקציה חדשה? האם גם אתם חושבים על רעיון לאפליקציה שעדיין אינה קיימת ואתם רוצים להגשים את החלום ולפתח אותה? כדי לפתח תוכנה, אתר או אפליקציה עליכם לבחור בית תוכנה המתמחה בפיתוח אפליקציות ותוכנות מתקדמות עבור לקוחות פרטיים, עסקים, תאגידים, יזמים וחברות הייטק.",
      "חברה מקצועית המתמחה בפיתוח אפליקציות ותוכנה יכולה להגשים את החלומות והרעיונות שלכם למוצר טכנולוגי מציאותי. יש יתרונות רבים ומשמעותיים לעבודה עם חברה לפיתוח אפליקציות ותוכנה בהתאמה אישית מלאה, לכן בחירת בית התוכנה היא קריטית עבורכם וחשוב לבחור היטב את חברת הפיתוח כדי שהרעיון שלכם יקרום עור וגידים ותיהנו מאפליקציה חדשנית עם ממשקים מעוצבים המבטיחים חוויית משתמש מלהיבה וייחודית."
    ],
  },
  section2: {
    h: "תהליך פיתוח מלא מקצה לקצה",
    p: [
      "יש הרבה בתי תוכנה בישראל מאחר ואנו חיים במדינת הסטארט-אפ ניישן, אך עדיין עליכם לבחור נכון את החברה לפיתוח הרעיון שלכם. חברת אפספורס הוקמה על ידי מומחים בתחום הפיתוח, חברתנו משתמשת רק בטכנולוגיות קצה חדשניות ובמתודולוגיות עבודה מתקדמות ואיכותיות על מנת להבטיח ללקוחות הרבים מוצרים מתקדמים ומושלמים.",
      <>
        {`חברתנו מציעה תהליכי פיתוח תוכנה או אפליקציה מקצה לקצה, כלומר התהליך מתחיל באפיון המוצר הטכנולוגי ולאחר הכנת מסמך אפיון מתחילים לעבוד על עיצוב התוכנה או האפליקציה וכמובן מבצעים את הפיתוח עצמו בטכנולוגיות המתאימות והמתקדמות ביותר. כל פרויקט מבוצע על ידי צוות מפתחים מנוסה, ללא סיוע במפתחים במיקור חוץ. אנשי הפיתוח בחברה הם מקצוענים בתחומם, לכל המפתחים יש ידע מעמיק וניסיון עשיר בפיתוח מערכות WEB, אפליקציות לטלפונים הניידים ו`}
        <Link to={URL.SAAS_CONSULTING_SERVICE} active>מערכות SaaS שונות</Link>.
        {` כמו כן, מחלקת העיצוב שלנו `}
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>מתמחה באפיון ועיצוב UI/UX</Link>.
      </>,
      "חשוב להדגיש כי פיתוח תוכנה או אפליקציה דורש תכנון מדויק ולפרטי פרטים, לכן אנו משקיעים משאבים רבים באפיון המוצר ורק אז אנחנו ניגשים למלאכת תכנון הארכיטקטורה של המערכת ולבחירת הטכנולוגיות המתאימות ביותר לפיתוח שלה. כדי לפתח אפליקציה מקצה לקצה יש להתייחס לגורמים ולמשתנים רבים, בין היתר לבחירת הטכנולוגיה לפיתוח צד לקוח וצד שרת ואחד הפרמטרים שיקבעו את הצלחת המערכת הוא עיצוב UI/UX."
    ],
  },
  section3: {
    h: "בחירה נכונה ומקצועית של טכנולוגיות הפיתוח",
    p: [
      "הצלחת התוכנה או האפליקציה שאתם מעוניינים לפתח בעזרת בית תוכנה תלוי בין היתר בבחירה נכונה של הטכנולוגיות. ברוב הפרויקטים יש לשלב מספר טכנולוגיות לכן חשוב לאפיין את הרעיון למוצר הטכנולוגי על ידי ארכיטקט תוכנה מנוסה. בשלב האפיון בוחרים את הפלטפורמות והטכנולוגיות לפיתוח המוצר או המערכת, להחלטה זו יש משמעות על עלויות הפיתוח ולוחות הזמנים, ובנוסף ההחלטה משפיעה על משאבי התמיכה העתידיים במערכת.",
      "אגב, כיום יש אפשרות לפתח תוכנה או אפליקציה על ידי פרילנסרים העובדים לבד, עם זאת עדיף תמיד לעבוד מול בית תוכנה מאחר וחברה מעסיקה אנשי פיתוח המיומנים בטכנולוגיות שונות, וכך אתם מרוויחים צוות פיתוח מיומן יותר והפרויקט שלכם יפותח על ידי אנשי מקצוע מנוסים ובעזרת פתרונות מתקדמים ואידיאליים.",
      <>
        {`רוב הפרויקטים כיום בתחום הפיתוח מבוססים על מערכות WEB לכן מומלץ לבחור רק חברות לפיתוח תוכנה ואפליקציות למובייל ולווב, ועליכם לוודא כי צוות החברה מתמחה בתחומים כמו `}
        <Link to={URL.FRONT_END_SERVICE} active>פיתוח פרונט אנד (Front End)</Link>,
        {` `}
        <Link to={URL.FULL_STACK_SERVICE} active>פיתוח פול סטאק (Full Stack)</Link>,
        {` `}
        <Link to={URL.SAAS_CONSULTING_SERVICE} active>פיתוח SaaS</Link>
        {` ו`}
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>אפיון ועיצוב ממשקי UI/UX</Link>.
      </>,
    ]
  },
  section4: {
    h: "מה היתרונות בעבודה עם בית תוכנה מוביל לפיתוח אפליקציות ומערכות ווב?",
    p: [
      <>
        {`פיתוח אפליקציות או תוכנה על ידי חברה מקצועית כמו `}
        <Link to={URL.HOME} active>בית התוכנה אפספורס</Link>
        {` מבטיח לכם יתרונות רבים וחשובים כגון:`}
      </>,
    ],
    ol: [
      {
        subH: "פיתוח מקצה לקצה",
        p: "בית תוכנה כמו אפספורס מעניק לכם שירותי פיתוח מקצה לקצה, כלומר תחת קורת גג אחת אתם זוכים לשירות מקיף המבטיח לכם מערכת מתקדמת וטכנולוגית עם ממשקים מעוצבים ומוצר איכותי וחדשני המספק חוויית משתמש ייחודית."
      },
      {
        subH: "טכנולוגיות חדשניות",
        p: "בעולם הפיתוח יש מגוון רחב של טכנולוגיות, שפות פיתוח ופלטפורמות לפיתוח אפליקציות ומערכות WEB, לכו חשוב לקבל ייעוץ מקצועי ולבחור את הטכנולוגיות המתקדמות והמתאימות ביותר למוצר ולרעיון שלכם."
      },
      {
        subH: "חיסכון בזמן",
        p: "בבית תוכנה מוביל יש צוות פיתוח השולט בטכנולוגיות מתקדמות שונות, וכל פרויקט מתחיל באפיון המערכת ובבחירת טכנולוגיות הפיתוח. בעזרת הצוות המיומן תוכלו לחסוך זמן רב והרעיון שלכם יהפוך למוצר טכנולוגי קיים בזמן הקצר ביותר, כך גם תפחיתו סיכון שאדם או חברה יחשבו על רעיון דומה וישחררו לעולם אפליקציה דומה לפניכם."
      },
      {
        subH: "חיסכון בעלויות הפיתוח",
        p: "כאשר מפתחים אפליקציה או מערכת ווב על ידי צוות פיתוח מיומן ניתן לחסון זמן ומשאבים, ועלויות הפיתוח זולות יותר בהשוואות לעלויות הנדרשות כאשר מפתחים את הרעיון על ידי פרילנסרים או בעזרת כמה אנשי פיתוח מחברות שונות."
      },
      {
        subH: "יציבות ותמיכה גם לאחר הפיתוח",
        p: "הרומן עם בית התוכנה בדרך כלל אינו מסתיים לאחר שלב הפיתוח ושחרור המוצר לאוויר, ברוב המקרים ממשיכים לעבוד על האפליקציה או התוכנה כדי לשפר אותה, לעצב מחדש ממשקים, להרחיב את הפיצ'רים הקיימים ולהתאים אותה לטכנולוגיות חדשות באינטרנט. לכן, חשוב לבחור היטב את בית התוכנה ויש לוודא ותק בענף, יציבות, מקצועיות ועוד."
      },
    ]
  },
  section5: {
    p: [
      "בעת בחירת בית התוכנה לפיתוח הרעיון שלכם מומלץ לערוך סקר שוק, להשוות הצעות מכמה בתי תוכנה, לקרוא מידע על חברות הפיתוח, לבדוק מהן ההתמחויות של כל חברה ומה החוזקות והחולשות שלה, לעיין במכתבי המלצה וביקורות על החברה וחשוב לבדוק פרויקטים קודמים שהיא ביצעה.",
      "בנוסף, עליכם לוודא כי בית התוכנה מסוגל לספק לכם מענה לפיתוח מקצה לקצה, רק כך תוכלו לחסוך זמן וכסף ותוכלו לפתח את האפליקציה או התוכנה בזמן קצר יחסית. גם הדרך עד להשלמת פרויקט הפיתוח חשובה עבורכם לכן כדאי לבדוק כימיה ותקשורת טובה עם צוות הפיתוח ומעצבי ה-UI/UX, וחשוב לבדוק איך מתבצע תהליך הפיתוח בחברה והאם בזמן הפיתוח מקבלים דיווחים שוטפים וניתן לעקוב אחר התקדמות העבודה."
    ]
  }
};