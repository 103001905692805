import * as React from "react";
import { graphql } from 'gatsby';
import Blog, {
  BlogSubHeader, BlogList, BlogListItem,
  BlogSubMiniHeader
} from '../../components/Blog';
import { Paragraph } from "../../components/Common";
import { HE } from '../../blogs/benefits-of-working-with-software-company';
import { HowToDoSoftwareDesignRight, ReactVsAngular, BenefitsOfWorkingWithSoftwareCompany, StagesOnAgileDevelopment } from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const articleSchemaData = {
  headline: "Benefits of working with software company",
  description: "SEO description benefits of working with software company",
  image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fsolutions-for-software-development.jpg?alt=media&token=1561b985-f46a-4721-8ff9-e12aae96e7db",
  datePublished: '2022-02-08'
};

const BenefitsOfWorkingWithSoftwareDompanyBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema("Benefits of working with software company", BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={BenefitsOfWorkingWithSoftwareCompany.url}
      banner="/benefits-of-working-with-software-company/banner.jpg"
      relatedBlogs={[ReactVsAngular, HowToDoSoftwareDesignRight, StagesOnAgileDevelopment]}
      title="Benefits of working with software company"
      seo={{
        title: 'SEO title benefits of working with software company',
        description: 'SEO description benefits of working with software company',
        image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fsolutions-for-software-development.jpg?alt=media&token=1561b985-f46a-4721-8ff9-e12aae96e7db',
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section2.h}
      </BlogSubHeader>
      {HE.section2.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section3.h}
      </BlogSubHeader>
      {HE.section3.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section4.h}
      </BlogSubHeader>
      {HE.section4.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogList>
        {HE.section4.ol.map(({ subH, p }) => (
          <BlogListItem>
            <BlogSubMiniHeader>{subH}</BlogSubMiniHeader>
            <Paragraph>{p}</Paragraph>
          </BlogListItem>
        ))}
        {HE.section5.p.map(p => <Paragraph>{p}</Paragraph>)}
      </BlogList>
    </Blog >
  )
}

export default BenefitsOfWorkingWithSoftwareDompanyBlog;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;